// ** Redux Imports
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getTracks = createAsyncThunk(
  "tracks/getTracks",
  async (params) => {
    console.log(params.category)
    const response = await axios.get(
      `/admin/v2/tracks?filters={"keyword":"${params.q}", "genre":"${params.category}", "date_from":"${params.date.startDate}", "date_to":"${params.date.endDate}"}&page=${params.page}&per_page=${params.perPage}`
    )
    return {
      params,
      data: response.data.data,
      totalPages: response.data.page_meta.total
    }
  }
)

export const getTrack = createAsyncThunk("tracks/getTrack", async (id) => {
  const response = await axios.get("/admin/v2/tracks", {id})
  console.log(response)
  return response.data
})

export const addTrack = createAsyncThunk(
  "tracks/addTrack",
  async (track, {dispatch, getState}) => {
    await axios.post("/admin/v2/tracks", track)
    await dispatch(getTracks(getState().tracks.params))
    return track
  }
)

export const updateTrack = createAsyncThunk(
  "tracks/updateTrack",
  async (trackId, data) => {
    const response = await axios.put(`/admin/v2/tracks/${trackId}`, data)
    await dispatch(getTracks(getState().tracks.params))
    return response.data
  }
)

export const deleteTracks = createAsyncThunk(
  "tracks/deleteTracks",
  async (id, {dispatch, getState}) => {
    await axios.delete(`/admin/v2/tracks/${id}`)
    await dispatch(getTracks(getState().tracks.params))
    return id
  }
)

export const trackListSlice = createSlice({
  name: "tracks",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    selectedTrack: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTracks.pending, (state) => {
        state.loading = true
      })
      .addCase(getTracks.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getTrack.fulfilled, (state, action) => {
        state.selectedTrack = action.payload
        state.loading = false
      })
  }
})

export default trackListSlice.reducer
