// ** Redux Imports
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getPlayHistories = createAsyncThunk(
  "playHistories/getPlayHistories",
  async (params) => {
    const response = await axios.get(
      `/admin/v2/play_histories?track_id=${params.id}&page=${params.page}&per_page=${params.perPage}`
    )
    console.log(response.data.page_meta.total)
    return {
      data: response.data.data,
      totalPages: response.data.page_meta.total
    }
  }
)

export const playHistoriesSlice = createSlice({
  name: "playHistories",
  initialState: {
    data: [],
    total: 0,
    params: {},
    trackHistory: [],
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlayHistories.pending, (state) => {
        state.loading = true
      })
      .addCase(getPlayHistories.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
  }
})

export default playHistoriesSlice.reducer
