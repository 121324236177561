// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const addPlayerSong = createAsyncThunk(
  "player/addPlayerSong",
  async (song) => {
    return song
  }
)

export const changePlayerIndex = createAsyncThunk(
  "player/changePlayerIndex",
  async (index) => {
    return index
  }
)

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    index: 0,
    isPlaying: false,
    songs: []
  },
  reducers: {
    playPlayerSong: (state) => {
      state.isPlaying = true
    },
    pausePlayerSong: (state) => {
      state.isPlaying = false
    },
    clearPlayerSong: (state) => {
      state.songs = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPlayerSong.fulfilled, (state, action) => {
        state.songs = [action.payload]
        state.isPlaying = true
      })
      .addCase(changePlayerIndex.fulfilled, (state, action) => {
        state.index = action.payload
        state.isPlaying = true
      })
  }
})

export const { playPlayerSong, pausePlayerSong, clearPlayerSong } =
  playerSlice.actions

export default playerSlice.reducer
