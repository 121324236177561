// ** Redux Imports
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getPlaylists = createAsyncThunk(
  "playlists/getPlaylists",
  async (params) => {
    const response = await axios.get(
      `/admin/v2/playlists?page=${params.page}&per_page=${params.perPage}&filters={"keyword":"${params.q}"}`,
      params
    )
    return {
      // params,
      data: response.data.data,
      totalPages: response.data.page_meta.total
    }
  }
)

// export const getUser = createAsyncThunk('appUsers/getUser', async id => {
//   const response = await axios.get('/api/users/user', { id })
//   return response.data.user
// })

// export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
//   await axios.post('/apps/users/add-user', user)
//   await dispatch(getData(getState().users.params))
//   await dispatch(getAllData())
//   return user
// })

// export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
//   await axios.delete('/apps/users/delete', { id })
//   await dispatch(getData(getState().users.params))
//   await dispatch(getAllData())
//   return id
// })

export const playListSlice = createSlice({
  name: "playlists",
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlaylists.pending, (state) => {
        state.loading = true
      })
      .addCase(getPlaylists.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
    // .addCase(getUser.fulfilled, (state, action) => {
    //   state.selectedUser = action.payload
    // })
  }
})

export default playListSlice.reducer
