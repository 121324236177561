import React, { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearPlayerSong, playPlayerSong, pausePlayerSong } from "@store/player"

import ReactJinkeMusicPlayer from "react-jinke-music-player" // music player library

import "react-jinke-music-player/assets/index.css"
import "./styles.scss"

const OPTIONS = {
  defaultPlayIndex: 0,
  theme: "light",
  bounds: "body",
  quietUpdate: false,
  // clearPriorAudioLists: true,
  autoPlayInitLoadPlayList: true,
  preload: true,
  glassBg: false,
  remember: false,
  remove: true,
  defaultPosition: {
    right: 100,
    bottom: 120
  },
  mode: "full",
  once: false,
  autoPlay: true,
  toggleMode: false,
  showMiniModeCover: true,
  showMiniProcessBar: false,
  drag: true,
  seeked: true,
  showMediaSession: false,
  showProgressLoadBar: true,
  showPlay: true,
  showDestroy: true,
  showReload: false,
  showThemeSwitch: false,
  showDownload: false,
  extendsContent: null,
  defaultVolume: 1,
  playModeShowTime: 600,
  loadAudioErrorPlayNext: true,
  autoHiddenCover: false,
  spaceBar: true,
  responsive: false,
  getContainer() {
    return document.body
  }
  // onBeforeDestroy(currentPlayId, audioLists, audioInfo) {
  //     return new Promise((resolve, reject) => {
  //         if (window.confirm('Are you confirm destroy the player?')) {
  //             resolve()
  //         } else {
  //             reject()
  //         }
  //     })
  // },
  // onDestroyed(currentPlayId, audioLists, audioInfo) {
  // },
  // onCoverClick(mode, audioLists, audioInfo) {
  // }
}

const MusicPlayer = () => {
  const dispatch = useDispatch()

  const {
    index: currentIndex,
    songs: playingSongs,
    isPlaying
  } = useSelector((state) => state.player)

  const [playOptions, setPlayOptions] = useState({})

  useEffect(() => {
    const playListOptions = {
      ...OPTIONS,
      clearPriorAudioLists: true,
      audioLists: playingSongs,
      currentIndex: currentIndex || 0
    }
    setPlayOptions(playListOptions)
  }, [playingSongs])

  const playerRef = useRef()

  useEffect(() => {
    if (playerRef && playerRef.current) {
      if (isPlaying) {
        playerRef.current.play()
      } else {
        playerRef.current.pause()
      }
    }
  }, [isPlaying])

  return (
    <div className="music-player">
      {playingSongs && playingSongs.length ? (
        <ReactJinkeMusicPlayer
          {...playOptions}
          getAudioInstance={(instance) => (playerRef.current = instance)}
          onBeforeDestroy={() => dispatch(clearPlayerSong())}
          onAudioPlay={() => dispatch(playPlayerSong())}
          onAudioPause={() => dispatch(pausePlayerSong())}
          {...OPTIONS}
          icon={{ next: null, prev: null }}
        />
      ) : null}
    </div>
  )
}

export default MusicPlayer
